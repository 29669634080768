import React, { useContext } from "react";
import { EthersContext } from "../Context/EthersContext";

// import dummyData from "../utils/dummyData";
// import { shortenAddress } from "../utils/shortenAddress";

const PatentsCard = () => {
 
  return (
    <div className="bg-[#181918] m-4 flex flex-1
      2xl:min-w-[450px]
      2xl:max-w-[500px]
      sm:min-w-[270px]
      sm:max-w-[300px]
      min-w-full
      flex-col p-3 rounded-md hover:shadow-2xl"
    >
      <div className="flex flex-col items-center w-full mt-3">
        <div className="display-flex justify-start w-full mb-6 p-2">
          <a href={`https://ropsten.etherscan.io/address/`} target="_blank" rel="noreferrer">
            <p className="text-white text-base">From: </p>
          </a>
          <a href={`https://ropsten.etherscan.io/address/`} target="_blank" rel="noreferrer">
            <p className="text-white text-base">To: </p>
          </a>
          <p className="text-white text-base">Amount: 100 ETH</p>
         
            <>
              <br />
              <p className="text-white text-base">Message: Hellow rold</p>
            </>
        
        </div>
        <img
          src="Image url"
          alt="nature"
          className="w-full h-64 2xl:h-96 rounded-md shadow-lg object-cover"
        />
        <div className="bg-black p-3 px-5 w-max rounded-3xl -mt-5 shadow-2xl">
          <p className="text-[#37c7da] font-bold">1222:566:8515</p>
        </div>
      </div>
    </div>
  );
};
let arr = [1,2,3, 4,5]

const Patents = () => {
    const {Blocks, setBlocks}= useContext(EthersContext)

  return (
    <div className="flex w-full justify-center items-center 2xl:px-20 gradient-bg-transactions">
      <div className="flex flex-col md:p-12 py-12 px-4">
        (
          <h3 className="text-white text-3xl text-center my-2">
            Latest Patents
          </h3>
     
          {/* <h3 className="text-white text-3xl text-center my-2">
            Connect your account to see the latest Patents
          </h3> */}
    

        <div className="flex flex-wrap justify-center items-center mt-10">
         {arr.map(()=>(<PatentsCard/>))}
            
        
        </div>
      </div>
    </div>
  );
};

export default Patents;
